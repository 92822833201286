import React from "react"
import './App.css';
import { Route, Switch } from "react-router-dom";
import Home from "./education CRM/Home";
import Navbar from "./education CRM/Navbar";
function App() {
  return (
    <>
    <Navbar/>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>

      </Switch>
    </>
  );
}

export default App;
